"use client";

import { Button, type ButtonProps } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { CheckIcon, CopyIcon } from "@radix-ui/react-icons";
import { forwardRef, useState } from "react";
import { toast } from "../ui/use-toast";

interface CopyButtonProps extends ButtonProps {
  disableTooltip?: boolean;
  tooltipDelayDuration?: number;
  tooltipDefaultText?: string;
  tooltipCopiedText?: string;
  label?: string;
}

const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  ({ className, onClick, variant = "ghost", ...props }, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [copied, setCopied] = useState<boolean>(false);
    const [mouseLeft, setMouseLeft] = useState<boolean>(false);

    const defaultText = props.tooltipDefaultText ?? "Copy";
    const copiedText = props.tooltipCopiedText ?? "Copied!";

    return (
      <TooltipProvider>
        <Tooltip
          delayDuration={props.tooltipDelayDuration ?? 0}
          open={open}
          onOpenChange={setOpen}
        >
          <TooltipTrigger asChild>
            <Button
              ref={ref}
              variant={variant}
              className={cn("px-sm h-auto", className)}
              {...props}
              onClick={(e) => {
                setCopied(true);
                setOpen(true);
                onClick?.(e);
              }}
              onMouseLeave={() => setMouseLeft(true)}
              onMouseEnter={() => {
                if (copied && mouseLeft) setCopied(false);
                setMouseLeft(false);
              }}
            >
              {copied ? (
                <CheckIcon className="text-text-secondary hover:text-text" />
              ) : (
                <CopyIcon className="text-text-secondary hover:text-text" />
              )}
            </Button>
          </TooltipTrigger>
          {!props.disableTooltip ? (
            <TooltipContent>{copied ? copiedText : defaultText}</TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    );
  }
);
CopyButton.displayName = "CopyButton";

export default CopyButton;

export function copyTextToClipboard(text: string, label?: string) {
  void navigator.clipboard.writeText(text).then(
    () => {
      toast({
        title: label ? `${label} copied to clipboard.` : "Copied to clipboard.",
        variant: "default",
      });
    },
    () => {
      toast({
        title: `Error copying ${label + " "}to clipboard.`,
        variant: "destructive",
      });
    }
  );
}
