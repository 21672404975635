export const AIFields = {
  CompanyWebsite: "CompanyWebsite" as AIFields,
  CompanySummary: "CompanySummary" as AIFields,
};

export type AIFields = "CompanyWebsite" | "CompanySummary";

export const AccountVerificationStatus = {
  NotStarted: "NotStarted" as AccountVerificationStatus,
  NotVerified: "NotVerified" as AccountVerificationStatus,
  Verified: "Verified" as AccountVerificationStatus,
};

export type AccountVerificationStatus =
  | "NotStarted"
  | "NotVerified"
  | "Verified";

export const AiModel = {
  GPT_3dot5_Turbo: "GPT_3dot5_Turbo" as AiModel,
  GPT_4dot5_Turbo: "GPT_4dot5_Turbo" as AiModel,
  PPX_llama3_32k_online: "PPX_llama3_32k_online" as AiModel,
  GPT_4o: "GPT_4o" as AiModel,
  PPX_llama31_128k_online: "PPX_llama31_128k_online" as AiModel,
  Claude_3dot5_Sonnet: "Claude_3dot5_Sonnet" as AiModel,
  Claude_3_Opus: "Claude_3_Opus" as AiModel,
};

export type AiModel =
  | "GPT_3dot5_Turbo"
  | "GPT_4dot5_Turbo"
  | "PPX_llama3_32k_online"
  | "GPT_4o"
  | "PPX_llama31_128k_online"
  | "Claude_3dot5_Sonnet"
  | "Claude_3_Opus";

export const AutomationTime = {
  Daily: "Daily" as AutomationTime,
  Weekly: "Weekly" as AutomationTime,
  Biweekly: "Biweekly" as AutomationTime,
  Monthly: "Monthly" as AutomationTime,
};

export type AutomationTime = "Daily" | "Weekly" | "Biweekly" | "Monthly";

export const AutomationType = {
  Funding: "Funding" as AutomationType,
  JobHiring: "JobHiring" as AutomationType,
  FDADrugApproval: "FDADrugApproval" as AutomationType,
  IntentTrigger: "IntentTrigger" as AutomationType,
};

export type AutomationType =
  | "Funding"
  | "JobHiring"
  | "FDADrugApproval"
  | "IntentTrigger";

export const BuiltinEnrichments = {
  CompanyLocation: "CompanyLocation" as BuiltinEnrichments,
  LastFundingInformation: "LastFundingInformation" as BuiltinEnrichments,
  CompanySize: "CompanySize" as BuiltinEnrichments,
  EstimatedRevenue: "EstimatedRevenue" as BuiltinEnrichments,
  CompanySummary: "CompanySummary" as BuiltinEnrichments,
};

export type BuiltinEnrichments =
  | "CompanyLocation"
  | "LastFundingInformation"
  | "CompanySize"
  | "EstimatedRevenue"
  | "CompanySummary";

export const CRMCustomFieldType = {
  LavaReachTriggerDescription:
    "LavaReachTriggerDescription" as CRMCustomFieldType,
  LavaReachTriggerSource: "LavaReachTriggerSource" as CRMCustomFieldType,
  LavaReachJobTitle: "LavaReachJobTitle" as CRMCustomFieldType,
  LavaReachLinkedInUrl: "LavaReachLinkedInUrl" as CRMCustomFieldType,
  LavaReachLinkedinHeadline: "LavaReachLinkedinHeadline" as CRMCustomFieldType,
  LavaReachLinkedinSummary: "LavaReachLinkedinSummary" as CRMCustomFieldType,
  LavaReachLeadSource: "LavaReachLeadSource" as CRMCustomFieldType,
  LavaReachFirstEmail: "LavaReachFirstEmail" as CRMCustomFieldType,
  LavaReachEmailStatus: "LavaReachEmailStatus" as CRMCustomFieldType,
  LavaReachOutReachStatus: "LavaReachOutReachStatus" as CRMCustomFieldType,
  LavaReachLeadScore: "LavaReachLeadScore" as CRMCustomFieldType,
  LavaReachLeadCategory: "LavaReachLeadCategory" as CRMCustomFieldType,
  LavaReachCustomAccountField:
    "LavaReachCustomAccountField" as CRMCustomFieldType,
  LavaReachCustomContactField:
    "LavaReachCustomContactField" as CRMCustomFieldType,
};

export type CRMCustomFieldType =
  | "LavaReachTriggerDescription"
  | "LavaReachTriggerSource"
  | "LavaReachJobTitle"
  | "LavaReachLinkedInUrl"
  | "LavaReachLinkedinHeadline"
  | "LavaReachLinkedinSummary"
  | "LavaReachLeadSource"
  | "LavaReachFirstEmail"
  | "LavaReachEmailStatus"
  | "LavaReachOutReachStatus"
  | "LavaReachLeadScore"
  | "LavaReachLeadCategory"
  | "LavaReachCustomAccountField"
  | "LavaReachCustomContactField";

export const CRMExportType = {
  Contacts: "Contacts" as CRMExportType,
  Leads: "Leads" as CRMExportType,
};

export type CRMExportType = "Contacts" | "Leads";

export const CRMSyncCadence = {
  Daily: "Daily" as CRMSyncCadence,
  Weekly: "Weekly" as CRMSyncCadence,
  Biweekly: "Biweekly" as CRMSyncCadence,
  Monthly: "Monthly" as CRMSyncCadence,
};

export type CRMSyncCadence = "Daily" | "Weekly" | "Biweekly" | "Monthly";

export const ConversationFeedback = {
  Positive: "Positive" as ConversationFeedback,
  Negative: "Negative" as ConversationFeedback,
  Neutral: "Neutral" as ConversationFeedback,
};

export type ConversationFeedback = "Positive" | "Negative" | "Neutral";

export const DuplicateStatus = {
  NotDuplicate: "NotDuplicate" as DuplicateStatus,
  DuplicateInOtherList: "DuplicateInOtherList" as DuplicateStatus,
  DuplicateInCurrentList: "DuplicateInCurrentList" as DuplicateStatus,
  DuplicateInBlockList: "DuplicateInBlockList" as DuplicateStatus,
};

export type DuplicateStatus =
  | "NotDuplicate"
  | "DuplicateInOtherList"
  | "DuplicateInCurrentList"
  | "DuplicateInBlockList";

export const EmailDeliveryStatus = {
  Delivered: "Delivered" as EmailDeliveryStatus,
  Bounced: "Bounced" as EmailDeliveryStatus,
  Spam: "Spam" as EmailDeliveryStatus,
};

export type EmailDeliveryStatus = "Delivered" | "Bounced" | "Spam";

export const EmailType = {
  Gmail: "Gmail" as EmailType,
  Outlook: "Outlook" as EmailType,
};

export type EmailType = "Gmail" | "Outlook";

export const EmailWarmUpStatus = {
  NotStarted: "NotStarted" as EmailWarmUpStatus,
  InProgress: "InProgress" as EmailWarmUpStatus,
  Finished: "Finished" as EmailWarmUpStatus,
};

export type EmailWarmUpStatus = "NotStarted" | "InProgress" | "Finished";

export const ExportedCRM = {
  HubSpot: "HubSpot" as ExportedCRM,
  Salesforce: "Salesforce" as ExportedCRM,
  Pipedrive: "Pipedrive" as ExportedCRM,
  Zoho: "Zoho" as ExportedCRM,
};

export type ExportedCRM = "HubSpot" | "Salesforce" | "Pipedrive" | "Zoho";

export const GenerateEmailStatus = {
  NotStarted: "NotStarted" as GenerateEmailStatus,
  InProgress: "InProgress" as GenerateEmailStatus,
  Finished: "Finished" as GenerateEmailStatus,
  Error: "Error" as GenerateEmailStatus,
  Requested: "Requested" as GenerateEmailStatus,
};

export type GenerateEmailStatus =
  | "NotStarted"
  | "InProgress"
  | "Finished"
  | "Error"
  | "Requested";

export const IntentCategory = {
  Call_Center_Software: "Call_Center_Software" as IntentCategory,
  Enterprise_Resource_Planning:
    "Enterprise_Resource_Planning" as IntentCategory,
  DataCentric_Security_Software:
    "DataCentric_Security_Software" as IntentCategory,
  Restaurant_POS_Systems: "Restaurant_POS_Systems" as IntentCategory,
};

export type IntentCategory =
  | "Call_Center_Software"
  | "Enterprise_Resource_Planning"
  | "DataCentric_Security_Software"
  | "Restaurant_POS_Systems";

export const JobMonitorCadence = {
  Daily: "Daily" as JobMonitorCadence,
  Weekly: "Weekly" as JobMonitorCadence,
  Monthly: "Monthly" as JobMonitorCadence,
};

export type JobMonitorCadence = "Daily" | "Weekly" | "Monthly";

export const JobRegion = {
  United_States: "United_States" as JobRegion,
  Canada: "Canada" as JobRegion,
};

export type JobRegion = "United_States" | "Canada";

export const JobScraperStatus = {
  NotStarted: "NotStarted" as JobScraperStatus,
  InProgress: "InProgress" as JobScraperStatus,
  Error: "Error" as JobScraperStatus,
  Finished: "Finished" as JobScraperStatus,
};

export type JobScraperStatus =
  | "NotStarted"
  | "InProgress"
  | "Error"
  | "Finished";

export const LeadStatus = {
  Unmarked: "Unmarked" as LeadStatus,
  MessageReplied: "MessageReplied" as LeadStatus,
  LinkOpened: "LinkOpened" as LeadStatus,
  MessageOpened: "MessageOpened" as LeadStatus,
  MessagesSent: "MessagesSent" as LeadStatus,
  UnsubscribedBounced: "UnsubscribedBounced" as LeadStatus,
};

export type LeadStatus =
  | "Unmarked"
  | "MessageReplied"
  | "LinkOpened"
  | "MessageOpened"
  | "MessagesSent"
  | "UnsubscribedBounced";

export const MonitorCadence = {
  Weekly: "Weekly" as MonitorCadence,
  Biweekly: "Biweekly" as MonitorCadence,
  Monthly: "Monthly" as MonitorCadence,
};

export type MonitorCadence = "Weekly" | "Biweekly" | "Monthly";

export const MonitorType = {
  RecentNews: "RecentNews" as MonitorType,
  Prompts: "Prompts" as MonitorType,
};

export type MonitorType = "RecentNews" | "Prompts";

export const NotificationCategory = {
  LeadReplied: "LeadReplied" as NotificationCategory,
  AccountEnrichmentDone: "AccountEnrichmentDone" as NotificationCategory,
  ContactEnrichmentDone: "ContactEnrichmentDone" as NotificationCategory,
  ICPGenerationDone: "ICPGenerationDone" as NotificationCategory,
  SequenceEnrollmentDone: "SequenceEnrollmentDone" as NotificationCategory,
  WarmLeadRegistered: "WarmLeadRegistered" as NotificationCategory,
  HotLeadRegistered: "HotLeadRegistered" as NotificationCategory,
  ContactCRMExportDone: "ContactCRMExportDone" as NotificationCategory,
  UploadContactsDone: "UploadContactsDone" as NotificationCategory,
  BlockListSyncFinished: "BlockListSyncFinished" as NotificationCategory,
  AccountProcessingDown: "AccountProcessingDown" as NotificationCategory,
  ErrorNotification: "ErrorNotification" as NotificationCategory,
};

export type NotificationCategory =
  | "LeadReplied"
  | "AccountEnrichmentDone"
  | "ContactEnrichmentDone"
  | "ICPGenerationDone"
  | "SequenceEnrollmentDone"
  | "WarmLeadRegistered"
  | "HotLeadRegistered"
  | "ContactCRMExportDone"
  | "UploadContactsDone"
  | "BlockListSyncFinished"
  | "AccountProcessingDown"
  | "ErrorNotification";

export const PlanType = {
  OneTime: "OneTime" as PlanType,
  Monthly: "Monthly" as PlanType,
  Yearly: "Yearly" as PlanType,
  NotPaid: "NotPaid" as PlanType,
};

export type PlanType = "OneTime" | "Monthly" | "Yearly" | "NotPaid";

export const PreScrapedJobSource = {
  Indeed: "Indeed" as PreScrapedJobSource,
  Linkedin: "Linkedin" as PreScrapedJobSource,
};

export type PreScrapedJobSource = "Indeed" | "Linkedin";

export const ReachoutStage = {
  NoEmail: "NoEmail" as ReachoutStage,
  FirstEmail: "FirstEmail" as ReachoutStage,
  SecondEmail: "SecondEmail" as ReachoutStage,
  ThirdEmail: "ThirdEmail" as ReachoutStage,
  Replied: "Replied" as ReachoutStage,
};

export type ReachoutStage =
  | "NoEmail"
  | "FirstEmail"
  | "SecondEmail"
  | "ThirdEmail"
  | "Replied";

export const SearchType = {
  DrugApproval: "DrugApproval" as SearchType,
  Funding: "Funding" as SearchType,
  Intent: "Intent" as SearchType,
  Job: "Job" as SearchType,
  JobV2: "JobV2" as SearchType,
  SearchEngine: "SearchEngine" as SearchType,
  CompanyDb: "CompanyDb" as SearchType,
};

export type SearchType =
  | "DrugApproval"
  | "Funding"
  | "Intent"
  | "Job"
  | "JobV2"
  | "SearchEngine"
  | "CompanyDb";

export const SequenceStage = {
  Active: "Active" as SequenceStage,
  Paused: "Paused" as SequenceStage,
  Finished: "Finished" as SequenceStage,
  Bounced: "Bounced" as SequenceStage,
  Replied: "Replied" as SequenceStage,
  Unsubscribed: "Unsubscribed" as SequenceStage,
  Delivering: "Delivering" as SequenceStage,
  NoEmail: "NoEmail" as SequenceStage,
  ManualFollowup: "ManualFollowup" as SequenceStage,
  NoLinkedin: "NoLinkedin" as SequenceStage,
};

export type SequenceStage =
  | "Active"
  | "Paused"
  | "Finished"
  | "Bounced"
  | "Replied"
  | "Unsubscribed"
  | "Delivering"
  | "NoEmail"
  | "ManualFollowup"
  | "NoLinkedin";

export const SequenceStatus = {
  NotStarted: "NotStarted" as SequenceStatus,
  Processing: "Processing" as SequenceStatus,
  Started: "Started" as SequenceStatus,
  Finished: "Finished" as SequenceStatus,
};

export type SequenceStatus =
  | "NotStarted"
  | "Processing"
  | "Started"
  | "Finished";

export const SequenceStepStage = {
  NoFeedback: "NoFeedback" as SequenceStepStage,
  Scheduled: "Scheduled" as SequenceStepStage,
  Delivered: "Delivered" as SequenceStepStage,
  Opened: "Opened" as SequenceStepStage,
  Replied: "Replied" as SequenceStepStage,
  Cancelled: "Cancelled" as SequenceStepStage,
  Bounced: "Bounced" as SequenceStepStage,
  Unsubscribed: "Unsubscribed" as SequenceStepStage,
};

export type SequenceStepStage =
  | "NoFeedback"
  | "Scheduled"
  | "Delivered"
  | "Opened"
  | "Replied"
  | "Cancelled"
  | "Bounced"
  | "Unsubscribed";

export const SequenceStepType = {
  Email: "Email" as SequenceStepType,
  Linkedin: "Linkedin" as SequenceStepType,
  LinkedinConnectionRequest: "LinkedinConnectionRequest" as SequenceStepType,
  LinkedinWaitForConnection: "LinkedinWaitForConnection" as SequenceStepType,
};

export type SequenceStepType =
  | "Email"
  | "Linkedin"
  | "LinkedinConnectionRequest"
  | "LinkedinWaitForConnection";

export const StripeSubscriptionType = {
  NoSubscription: "NoSubscription" as StripeSubscriptionType,
  Credits19dot99: "Credits19dot99" as StripeSubscriptionType,
  Growth99: "Growth99" as StripeSubscriptionType,
};

export type StripeSubscriptionType =
  | "NoSubscription"
  | "Credits19dot99"
  | "Growth99";

export const TemplateField = {
  first_name: "first_name" as TemplateField,
  last_name: "last_name" as TemplateField,
  job_title: "job_title" as TemplateField,
  job_function: "job_function" as TemplateField,
  headline: "headline" as TemplateField,
  bio: "bio" as TemplateField,
  personal_linkedin: "personal_linkedin" as TemplateField,
  company_name: "company_name" as TemplateField,
  company_summary: "company_summary" as TemplateField,
  company_website: "company_website" as TemplateField,
  company_location: "company_location" as TemplateField,
  company_revenue: "company_revenue" as TemplateField,
  company_size: "company_size" as TemplateField,
  company_keywords: "company_keywords" as TemplateField,
  funding_history: "funding_history" as TemplateField,
  company_industry: "company_industry" as TemplateField,
  relevant_techstack: "relevant_techstack" as TemplateField,
};

export type TemplateField =
  | "first_name"
  | "last_name"
  | "job_title"
  | "job_function"
  | "headline"
  | "bio"
  | "personal_linkedin"
  | "company_name"
  | "company_summary"
  | "company_website"
  | "company_location"
  | "company_revenue"
  | "company_size"
  | "company_keywords"
  | "funding_history"
  | "company_industry"
  | "relevant_techstack";

export const TemplateType = {
  Account: "Account" as TemplateType,
  Contact: "Contact" as TemplateType,
  ContactMessages: "ContactMessages" as TemplateType,
};

export type TemplateType = "Account" | "Contact" | "ContactMessages";

export const TriggerSource = {
  Linkedin: "Linkedin" as TriggerSource,
  JobPost: "JobPost" as TriggerSource,
  FundingEvent: "FundingEvent" as TriggerSource,
  GlassDoor: "GlassDoor" as TriggerSource,
  NoTrigger: "NoTrigger" as TriggerSource,
};

export type TriggerSource =
  | "Linkedin"
  | "JobPost"
  | "FundingEvent"
  | "GlassDoor"
  | "NoTrigger";

export const UnitsToWait = {
  Days: "Days" as UnitsToWait,
  Hours: "Hours" as UnitsToWait,
};

export type UnitsToWait = "Days" | "Hours";

export const WorkflowDataSource = {
  WebsiteVisitor: "WebsiteVisitor" as WorkflowDataSource,
  ManualUpload: "ManualUpload" as WorkflowDataSource,
  API: "API" as WorkflowDataSource,
};

export type WorkflowDataSource = "WebsiteVisitor" | "ManualUpload" | "API";

export type _prisma_migrations = {
  id: string;
  checksum: string;
  finishedAt: string | null;
  migrationName: string;
  logs: string | null;
  rolledBackAt: string | null;
  startedAt: string;
  appliedStepsCount: number;
};

export type SavedSearches = {
  id: string;
  clerkOrgId: string;
  searchType: SearchType;
  searchCriteria: unknown;
  searchName: string;
  createdBy: string;
  createdAt: string;
  isAutoSave: boolean;
};

export type _contactToSequences = {
  a: string;
  b: string;
};

export type PrescrapedJobPostings = {
  id: string;
  postedTime: string;
  jobKeyword: string;
  title: string;
  jobLink: string;
  jobLocation: string | null;
  companyName: string | null;
  companyLink: string | null;
  companyReviewCount: number | null;
  jobDescription: string | null;
  salaryEstimate: string | null;
  scrappedDate: string;
  jobBenefits: string | null;
  jobSource: PreScrapedJobSource;
  jobFunction: string | null;
  jobShift: string | null;
  jobType: string | null;
  jobTypeLocation: string | null;
  industries: string | null;
  jobRegion: JobRegion;
};

export type ContactEnrichmentFailure = {
  id: string;
  clerkOrgId: string;
  contactFirstName: string;
  contactLastName: string;
  contactLinkedin: string | null;
  contactEmail: string;
  companyName: string;
  companyWebsite: string | null;
};

export type MarketingEmailAudience = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  createdAt: string;
  unsubscribed: boolean;
};

export type CompanyProfileV2 = {
  id: string;
  primaryDomain: string;
  linkedinUrl: string | null;
  twitterUrl: string | null;
  companyName: string;
  companySummary: string | null;
  industry: string | null;
  foundedYear: number | null;
  location: string | null;
  revenue: string | null;
  employeeNum: string | null;
  keywords: string[] | null;
  technologies: string[] | null;
  seoDescription: string | null;
  shortDescription: string | null;
  city: string | null;
  country: string | null;
  state: string | null;
  lastFundingDescription: string | null;
  lastFundingUrl: string | null;
  publiclyTradedExchange: string | null;
  publiclyTradedSymbol: string | null;
  acquisitionHistory: string[] | null;
  fundingHistory: string[] | null;
};

export type UploadContactJob = {
  id: string;
  clerkOrgId: string;
  email: string;
  collapseAccounts: boolean;
  listId: string;
  syncCompany: boolean;
  sequenceId: string | null;
};

export type EmailDelivery = {
  id: string;
  clerkOrgId: string;
  userEmailId: string;
  emailDeliveryStatus: EmailDeliveryStatus;
  date: string;
};

export type ContactsToUpload = {
  id: string;
  jobId: string;
  companyName: string;
  companySize: string | null;
  companyIndustry: string | null;
  domain: string | null;
  customFields: string[] | null;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  email: string | null;
  phone: string | null;
  linkedin: string | null;
  companySummary: string | null;
  companyLocation: string | null;
  companyRevenue: string | null;
  companyLinkedin: string | null;
};

export type ApolloKeys = {
  id: string;
  apiKey: string;
  account: string;
  hasCredit: boolean;
};

export type List = {
  id: string;
  createdAt: string;
  name: string;
  tags: string[] | null;
  description: string | null;
  ICPs: string[] | null;
  clerkOrgId: string;
  createdBy: string | null;
  relevantTechStack: string[] | null;
  isProcessing: boolean;
  sequenceId: string | null;
  sequenceStarted: boolean;
  sequenceStatus: SequenceStatus;
  isProcessingAccountEnrichment: boolean;
  isProcessingContactEnrichment: boolean;
  isProcessingEmails: boolean;
  isProcessingNewAccounts: boolean;
  isProcessingUpload: boolean;
  isProcessingICP: boolean;
  lastUpdated: string;
  isDeleted: boolean;
  lastExported: string | null;
  lastExportedType: string | null;
  crmSyncCadence: CRMSyncCadence;
  crmSyncOn: boolean;
  exportCRM: ExportedCRM;
  exportCRMType: CRMExportType;
  leadScoreSyncThreshold: number;
  workflowId: string | null;
  clerkUserId: string | null;
};

export type PersonProfileV2 = {
  id: string;
  linkedinUrl: string;
  firstName: string;
  lastName: string;
  jobTitle: string | null;
  jobFunction: string | null;
  phone: string | null;
  phoneType: string | null;
  email: string | null;
  emailStatus: string | null;
  summary: string | null;
  languages: string[] | null;
  companyProfileId: string | null;
  headline: string | null;
};

export type PromptTemplate = {
  id: string;
  createdAt: string;
  clerkOrgId: string;
  name: string;
  template: string;
  createdBy: string;
};

export type AccountsV2 = {
  id: string;
  dateCreated: string;
  triggerDescription: string;
  triggerSource: string;
  listId: string | null;
  unverifiedCompanyDomain: string | null;
  unverifiedCompanyName: string | null;
  generateICP: GenerateEmailStatus;
  clerkOrgId: string;
  duplicatedAccountId: string | null;
  extraTriggers: string[] | null;
  unverifiedCompanyDescription: string | null;
  duplicateStatus: DuplicateStatus;
  overrideCompanyLocation: string | null;
  accountVerificationStatus: AccountVerificationStatus;
  unverifiedCompanyRevenue: string | null;
  overrideCompanySize: string | null;
  aiGeneratedFields: AIFields[] | null;
  companyIndustry: string | null;
  companyLinkedin: string | null;
  companyCity: string | null;
  companyCountry: string | null;
  companyKeywords: string[] | null;
  companyState: string | null;
  companyTechStack: string[] | null;
  extraTriggersGenerating: string[] | null;
  extraTriggersGeneratingFinished: string[] | null;
  accountCardCache: string[] | null;
  lastOpened: string;
  crmIds: string[] | null;
  exportedCRMs: ExportedCRM[] | null;
  autoCRMSync: boolean;
  lastSynced: string | null;
  lastUpdated: string;
};

export type ContactCardChatMessage = {
  id: string;
  contactId: string;
  clerkOrgId: string;
  message: string;
  role: string;
  createdAt: string;
  clerkUserId: string | null;
  feedback: ConversationFeedback;
};

export type ContactsV2 = {
  id: string;
  accountId: string;
  overridenEmail: string | null;
  generateEmailStatus: GenerateEmailStatus;
  overridenPhone: string | null;
  clerkOrgId: string;
  duplicateStatus: DuplicateStatus;
  duplicatedContactId: string | null;
  overridenBio: string | null;
  overridenHeadline: string | null;
  exportedCRMs: ExportedCRM[] | null;
  dateCreated: string;
  listId: string | null;
  sequenceId: string | null;
  sequenceEmails: string[] | null;
  extraEnrichments: string[] | null;
  sequenceSubject: string | null;
  firstName: string | null;
  jobTitle: string | null;
  lastName: string | null;
  linkedin: string | null;
  phoneStatus: string | null;
  emailStatus: string | null;
  source: string | null;
  generatingEnrichmentFields: string[] | null;
  finishedEnrichmentFields: string[] | null;
  leadStatus: LeadStatus;
  crmIds: string[] | null;
  lastSynced: string | null;
  lastUpdated: string;
  extraSequenceSubjects: string[] | null;
  triedEmailEnrichment: boolean;
  triedPhoneEnrichment: boolean;
  phoneSource: string | null;
};

export type EmailSettings = {
  id: string;
  clerkOrgId: string;
  verifiedIdentifies: string[] | null;
  blockedEmails: string[] | null;
  daysOfWeek: number[] | null;
  hoursOfDayEnd: number;
  hoursOfDayStart: number;
  timeZoneOffSetMinutes: number;
  timezone: string;
  blockedDomains: string[] | null;
  unsubscribedEmails: string[] | null;
};

export type CachedAITemplates = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  aiModel: AiModel;
  naturalLanguageSentence: string;
  template: string;
  columnKey: string;
  createdBy: string;
  listId: string | null;
  templateType: TemplateType;
};

export type LinkedinIntent = {
  id: string;
  intent: IntentCategory;
  scrappedDate: string;
  contactName: string;
  contactTitle: string;
  department: string;
  seniority: string;
  contactLinkedin: string;
  contactEmail: string;
  contactPhone: string;
  contactLocation: string;
  companyName: string;
  companyWebsite: string;
  companyLocation: string;
  companyDescription: string;
  companyIndustry: string;
  companyLinkedin: string;
  companyRevenue: string;
  companySize: string;
  hash: string;
};

export type LinkDBCompany = {
  id: string;
  linkedDbId: string;
  name: string;
  description: string | null;
  tagline: string | null;
  website: string | null;
  industry: string | null;
  foundedYear: number | null;
  searchId: string | null;
  companySizeOnLinkedin: number | null;
  companySizeMin: number | null;
  companySizeMax: number | null;
  followerCount: number | null;
  linkedinInternalId: string | null;
  angellistUrl: string | null;
  facebookUrl: string | null;
  twitterUrl: string | null;
  domain: string | null;
  stockExchange: string | null;
  stockSymbol: string | null;
  companyType: string | null;
  specialities: string[] | null;
  city: string | null;
  country: string | null;
  geographicArea: string | null;
  isHq: boolean | null;
  line1: string | null;
  postalCode: string | null;
  state: string | null;
};

export type BattleCardChatMessage = {
  id: string;
  accountId: string;
  clerkOrgId: string;
  message: string;
  role: string;
  createdAt: string;
  clerkUserId: string | null;
};

export type AccountICPJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  accountIdsRequested: string[] | null;
  accountIdsFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
};

export type WebScraperEnrichment = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  listId: string;
  accountId: string;
  enrichmentTemplate: string;
  enrichmentFieldKey: string;
  enrichmentValue: string | null;
  finished: boolean;
  notifyEmail: string;
};

export type OnboardingSurvey = {
  id: string;
  createdAt: string;
  clerkUserId: string;
  companySize: string | null;
  companyName: string | null;
  companyWebsite: string | null;
  companyDescription: string | null;
  useCase: string[] | null;
  userRole: string;
};

export type UserOnboarding = {
  id: string;
  clerkUserId: string;
  onboardingStep: number;
  featuresUsed: string[] | null;
};

export type CreditUsageLog = {
  id: string;
  clerkOrgId: string;
  creditsUsed: number;
  creditsLeft: number;
  description: string;
  date: string;
  usedBy: string;
  type: string;
  clerkUserId: string | null;
};

export type ContactChatConversation = {
  id: string;
  conversationName: string;
  clerkOrgId: string;
  clerkUserId: string | null;
  contactId: string;
  messages: unknown;
  createdAt: string;
};

export type AccountChatConversation = {
  id: string;
  conversationName: string;
  clerkUserId: string | null;
  accountId: string;
  clerkOrgId: string;
  messages: unknown;
  createdAt: string;
  feedback: ConversationFeedback;
};

export type LinkDbSimilarCompanies = {
  id: string;
  name: string;
  link: string;
  industry: string | null;
  companyId: string;
  index: number;
  location: string | null;
};

export type UserEmail = {
  id: string;
  email: string;
  accessToken: string | null;
  refreshToken: string;
  clerkOrgId: string;
  emailSettingsId: string | null;
  emailType: EmailType;
  emailsSentToday: number;
  maxEmailsPerDay: number;
  displayName: string;
  warmUpDayNumber: number;
  warmUpStatus: EmailWarmUpStatus;
  warmUpEmailsPerDay: number;
  authProblem: boolean;
  authProblemDate: string | null;
  authProblemMessage: string | null;
  signatureBody: string | null;
  useSignature: boolean;
};

export type EmailTemplate = {
  id: string;
  identifier: string;
  variant: string;
  template: string;
  templateFields: TemplateField[] | null;
  temperaturePercent: number;
  description: string | null;
  maxLength: number | null;
  lastUpdated: string;
  subject: string | null;
  clerkOrgId: string;
  customFieldNames: string[] | null;
  aiModel: AiModel;
  templateType: TemplateType;
};

export type ContactEnrichmentJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  enrichmentFieldsRequested: string[] | null;
  enrichmentFieldsFinished: string[] | null;
  contactIdsRequested: string[] | null;
  contactIdsFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
};

export type NotificationSettings = {
  id: string;
  clerkOrgId: string;
  slackEnabled: boolean;
  emailEnabled: boolean;
  notificationSettingCategory: NotificationCategory;
};

export type ShortLink = {
  id: string;
  createdAt: string;
  clerkOrgId: string;
  redirectLink: string;
  sequenceStepId: string | null;
  sequenceId: string | null;
};

export type CSVUploadJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  contactsToUploadRequested: string[] | null;
  contactsToUploadFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
};

export type ShortLinkClick = {
  id: string;
  shortLinkId: string;
  date: string;
  ip: string;
  country: string;
  city: string;
  region: string;
  timezone: string;
  userAgent: string;
  referer: string;
  os: string;
  browser: string;
  device: string;
  contactId: string | null;
  sequenceStepStatusId: string | null;
};

export type EmailSequenceStepStatus = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  emailSequenceStepId: string | null;
  contactId: string;
  sequenceFeedback: SequenceStepStage;
  emailBody: string | null;
  emailGenerateStatus: GenerateEmailStatus;
  emailThreadId: string;
  sequenceStatusId: string;
  daysWaited: number;
  index: number;
  increaseDaysWaited: boolean;
  hoursWaited: number;
  emailSentDate: string | null;
  linkOpened: boolean;
  repliedBody: string | null;
  linkOpenCount: number;
  emailOpenCount: number;
  emailSubject: string | null;
  emailRepliedDate: string | null;
};

export type EmailSequenceStatus = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  emailSequenceId: string;
  contactId: string;
  stage: SequenceStage;
  generatedSubject: string | null;
  inboxUsed: string | null;
  isPaused: boolean;
  hasEmailSteps: boolean;
  hasLinkedinSteps: boolean;
  linkedInUsed: string | null;
  bouncedDate: string | null;
  connectionAcceptedDate: string | null;
  connectionSentDate: string | null;
  repliedDate: string | null;
  unsubscribedDate: string | null;
  leadScore: number;
  finishedDate: string | null;
  startDeliveringDate: string | null;
  skipReplyCheck: boolean;
};

export type SearchToListJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  searchIdsRequested: string[] | null;
  searchIdsFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
};

export type EmailSequence = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  identifier: string;
  templateFields: TemplateField[] | null;
  customFieldNames: string[] | null;
  aiModel: AiModel;
  description: string | null;
  subjectTemplate: string;
  bccEmails: string[] | null;
  ccEmails: string[] | null;
  replyForwardEmails: string[] | null;
  blockedEmails: string[] | null;
  daysOfWeek: number[] | null;
  hoursOfDayEnd: number;
  hoursOfDayStart: number;
  timeZoneOffSetMinutes: number;
  timezone: string;
  unsubscribedEmails: string[] | null;
  processingContacts: boolean;
  isProcessingGenEmail: boolean;
  isProcessingUploads: boolean;
  includeUnsubscribed: boolean;
  emailsOpened: number;
  emailsSent: number;
  linkedinMessagesSent: number;
  linkedinRequestApproved: number;
  linkedinRequestSent: number;
  diableTrackOpen: boolean;
  skipConnectedLinkedin: boolean;
  verifyEmailBeforeSend: boolean;
  isDeleted: boolean;
  diableLinkTracking: boolean;
  sendTextOnlyEmails: boolean;
};

export type CrunchbaseFunding = {
  id: string;
  cbCompanyName: string;
  cbCompanyLink: string | null;
  organizationLocation: string | null;
  cbTransactionName: string | null;
  cbTransactionUrl: string;
  cbFundingType: string;
  cbCompanyWebsite: string | null;
  cbRevenueRange: string | null;
  annouceDate: string | null;
  usdRaised: string | null;
  cbOrganizationDescription: string | null;
  cbOrganizationIndustries: string | null;
};

export type EmailSequenceSteps = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  bodyTemplate: string;
  daysToWait: number;
  emailSequenceId: string;
  unitsToWait: UnitsToWait;
  index: number;
  type: SequenceStepType;
  sendAsNewThread: boolean;
  overrideSubjectTemplate: string | null;
};

export type AccountEnrichmentJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  enrichmentFieldsRequested: string[] | null;
  enrichmentFieldsFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
  accountIdsFinished: string[] | null;
  accountIdsRequested: string[] | null;
};

export type SlackIntegration = {
  id: string;
  clerkOrgId: string;
  slackBotUserId: string;
  slackBotAccessToken: string;
  channelId: string | null;
  channelName: string | null;
  teamId: string;
};

export type EmailThread = {
  id: string;
  clerkOrgId: string;
  threadId: string | null;
  subject: string | null;
  lastUpdated: string;
  inboxUsedId: string | null;
  finished: boolean;
  sendToEmail: string | null;
  firstEmailSentDate: string | null;
  linkedinConnectionApproved: boolean;
  linkedinConversationUrn: string | null;
  linkedinFirstMessageDate: string | null;
  linkedinId: string | null;
  linkedinSentConnection: boolean;
  linkedinConnectionRequestedDate: string | null;
  emailSequenceId: string | null;
  manualFollowup: boolean;
  previousThreadIds: string[] | null;
};

export type ShortLinkDetails = {
  id: string;
  createdAt: string;
  clerkOrgId: string;
  shortLinkId: string;
  sequenceStepStatusId: string;
  contactId: string;
};

export type ColumnSource = {
  id: string;
  clerkOrgId: string;
  columnNames: string[] | null;
  columnTypes: string[] | null;
  columnSources: unknown;
  listId: string;
};

export type AccountMonitorUpdates = {
  id: string;
  date: string;
  clerkOrgId: string;
  clerkUserId: string;
  monitorId: string | null;
  updatesType: MonitorType;
  updates: string[] | null;
  read: boolean;
};

export type AccountsMonitor = {
  id: string;
  name: string;
  listId: string;
  clerkOrgId: string;
  clerkUserId: string;
  monitorType: MonitorType;
  monitorPrompts: string[] | null;
  monitorCadence: MonitorCadence;
  emailBack: string[] | null;
  createdBy: string;
};

export type _EmailSequenceToUserEmail = {
  a: string;
  b: string;
};

export type UserLinkedin = {
  id: string;
  linkedinProfielId: string;
  authCookie: string;
  authCookieCreatedDate: string;
  clerkOrgId: string;
  emailSettingsId: string | null;
  msgSentToday: number;
  maxMsgPerDay: number;
  authProblem: boolean;
  authProblemMessage: string | null;
  authProblemDate: string | null;
  connectionRequestSentToday: number;
  displayName: string | null;
  maxConnectionRequestPerDay: number;
  selfProfileUrn: string | null;
  bcookie: string | null;
  bscookie: string | null;
  alertEmail: string | null;
};

export type Workflow = {
  id: string;
  clerkOrgId: string;
  name: string;
  enrichments: unknown;
  datasource: WorkflowDataSource;
  fetchTitles: string[] | null;
  sequenceId: string | null;
  sendEmailImmediately: boolean;
  emailNotificationEnabled: boolean;
  slackNotificationEnabled: boolean;
  emailNotificationEmails: string[] | null;
  onlyEnrichWithContacts: boolean;
};

export type FDADrugApprovals = {
  id: string;
  approvalDate: string;
  drugName: string;
  drugCode: string;
  submissionCode: string;
  activeIngredient: string;
  companyName: string;
  companyWebsite: string | null;
  parentCompanyName: string | null;
  submissionClassification: string | null;
  submissionStatus: string;
};

export type Subscriptions = {
  id: string;
  clerkOrgId: string;
  creditsUsed: number;
  maxCredits: number;
  renewDate: number;
  noCreditEmailSent: boolean;
  planType: PlanType;
  maxInboxes: number;
  oneTimeCredits: number;
  accountCreated: string;
  companyDescription: string | null;
  companyName: string | null;
  companySize: string | null;
  companyWebsite: string | null;
  onboardingSurveyFilled: boolean;
  orgName: string | null;
  simpleUI: boolean;
  paymentIssues: boolean;
  stripeCustomerId: string | null;
  stripeSubscriptionId: string | null;
  stripeSubscriptionType: StripeSubscriptionType;
  enableCompanySearch: boolean;
  enableCreateOrg: boolean;
  enableAllEnrichments: boolean;
};

export type _EmailSequenceToUserLinkedin = {
  a: string;
  b: string;
};

export type VisitTracking = {
  id: string;
  url: string;
  clerkOrgId: string;
  visitId: string;
  date: string;
  ip: string;
  country: string;
  city: string;
  region: string;
  timezone: string;
  userAgent: string;
  referer: string;
  os: string;
  browser: string;
  device: string;
  contactId: string | null;
};

export type WarmUpEmailTarget = {
  id: string;
  lastUpdated: string;
  clerkOrgId: string;
  inboxUsedId: string;
  sendToEmail: string;
  emailBody: string;
  emailSubject: string;
  emailThreadId: string;
  sequenceFeedback: SequenceStepStage;
};

export type ContactGenEmailJob = {
  id: string;
  clerkOrgId: string;
  listId: string;
  sequenceId: string;
  contactsRequested: string[] | null;
  contactsFinished: string[] | null;
  emailBackTo: string | null;
  finished: boolean;
};

export type WebsiteVisitorTracking = {
  id: string;
  clerkOrgId: string;
  websiteDomain: string | null;
  isTracking: boolean;
};

export type OrgChartCache = {
  id: string;
  clerkOrgId: string;
  lastUpdated: string;
  createdBy: string | null;
  teamName: string;
  titles: string[] | null;
};

export type PerplexityKeyRotation = {
  id: string;
  counter: number;
};

export type SharedList = {
  id: string;
  originalListId: string | null;
  originalClerkOrgId: string;
  copiedListId: string[] | null;
  sharedBy: string;
  sharedDate: string;
  enabled: boolean;
  copyOrgs: string[] | null;
  viewCount: number;
};

export type JobChangeMonitorEntry = {
  id: string;
  clerkOrgId: string;
  linkedinUrl: string;
  lastUpdated: string;
  jobChangeMonitorListId: string | null;
  name: string;
  newLinkedinDescription: string;
  newLinkedinCompany: string;
  oldLinkedinCompany: string;
  oldLinkedinDescription: string;
  newLinkedinCompanyUrl: string | null;
  oldLinkedinCompanyUrl: string | null;
};

export type JobChangeMonitorList = {
  id: string;
  clerkOrgId: string;
  notificationEmails: string[] | null;
  cadence: JobMonitorCadence;
};

export type EmailThreadReply = {
  id: string;
  lastUpdated: string;
  emailSentDate: string;
  clerkOrgId: string;
  repliedBody: string | null;
  emailThreadId: string;
  sequenceStatusId: string;
  index: number;
  contactId: string;
};

export type EmailFetchPerformance = {
  id: string;
  clerkOrgId: string;
  emailBounced: boolean;
  emailFeteched: string;
  fetchSuccess: boolean;
  emailSource: string;
  contactName: string;
  contactLinkedin: string | null;
  contactCompanyName: string;
  contactCompanyWebsite: string | null;
};

export type PhoneFetchPerformance = {
  id: string;
  clerkOrgId: string;
  phoneFeteched: string;
  phoneSource: string;
  fetchSuccess: boolean;
  contactName: string;
  contactLinkedin: string | null;
  contactCompanyName: string;
  contactCompanyWebsite: string | null;
};

export type CRMCustomFields = {
  id: string;
  clerkOrgId: string;
  crm: ExportedCRM;
  fieldType: CRMCustomFieldType;
  fieldId: string;
  enabled: boolean;
  customTriggerName: string | null;
};

export type ResearchSections = {
  id: string;
  clerkOrgId: string;
  userId: string | null;
  sections: string[] | null;
};

export type OptOutEmails = {
  id: string;
  clerkOrgId: string;
  email: string;
};

export type OpportunitiesSynced = {
  id: string;
  clerkOrgId: string;
  lastSynced: string;
  crmType: ExportedCRM;
  opportunityIds: string[] | null;
};

export type AccountSettings = {
  id: string;
  clerkOrgId: string;
  defaultICPs: string[] | null;
  defaultEmailTemplateId: string | null;
  customEnrichments: string[] | null;
  builtinEnrichments: BuiltinEnrichments[] | null;
  relevantTechStacks: string[] | null;
  defaultExportCRM: ExportedCRM;
  defaultExportType: CRMExportType;
  drugApprovalSearchHistory: unknown;
  fundingSearchHistory: unknown;
  intentSearchHistory: unknown;
  jobSearchHistory: unknown;
  searchEngineHistory: unknown;
  companyDbSearchHistory: unknown;
  jobSearchV2History: unknown;
  integratedCRMs: ExportedCRM[] | null;
};

export type EmailSequenceStepStatusCache = {
  id: string;
  clerkOrgId: string;
  repliedBody: string | null;
  sequenceFeedback: SequenceStepStage;
  linkOpened: boolean;
  emailSubject: string;
  emailBody: string;
  isLinkedIn: boolean;
  emailSequenceStepStatusId: string;
  emailOpenCount: number;
  linkOpenCount: number;
};
