// Copied and modified from https://github.com/JaleelB/emblor

import { cn } from "@/lib/utils";
import React from "react";
import CopyButton from "../functional/copy-button";
import { Tag, type TagProps } from "./tag";
import type { TagInputStyleClassesProps, Tag as TagType } from "./tag-input";

export type TagListProps = {
  tags: TagType[];
  customTagRenderer?: (tag: TagType, isActiveTag: boolean) => React.ReactNode;
  onRemoveTag: (tag: string, index: number) => void;
  direction?: TagProps["direction"];
  inlineTags?: boolean;
  activeTagIndex?: number | null;
  classStyleProps: {
    tagListClasses: TagInputStyleClassesProps["tagList"];
    tagClasses: TagInputStyleClassesProps["tag"];
  };
  disabled?: boolean;
} & Omit<TagProps, "tag" | "onRemoveTag">;

export const TagList: React.FC<TagListProps> = ({
  tags,
  customTagRenderer,
  onRemoveTag,
  direction,
  draggable,
  inlineTags,
  activeTagIndex,
  classStyleProps,
  disabled,
  ...tagListProps
}) => {
  const [draggedTagIndex, setDraggedTagIndex] = React.useState<number | null>(
    null
  );

  const handleMouseDown = (index: number) => {
    setDraggedTagIndex(index);
  };

  const handleMouseUp = () => {
    setDraggedTagIndex(null);
  };

  const copyToClipboard = () => {
    const text = tags.join(",");
    void navigator.clipboard.writeText(text);
  };

  if (!inlineTags) {
    return (
      <div
        className={cn(
          "rounded-md w-full max-h-[300px] overflow-y-auto",
          {
            "flex flex-wrap gap-2": direction === "row",
            "flex flex-col gap-2": direction === "column",
          },
          classStyleProps?.tagListClasses?.container
        )}
      >
        {tags.length > 0 && <CopyButton onClick={copyToClipboard} />}
        {draggable ? (
          <div
            className={`flex flex-wrap gap-2 list ${classStyleProps?.tagListClasses?.sortableList}`}
          >
            {tags.map((tagObj, index) => (
              <div key={index}>
                <div
                  onMouseDown={() => handleMouseDown(index)}
                  onMouseLeave={handleMouseUp}
                  className={cn(
                    {
                      "border border-solid border-primary rounded-md":
                        draggedTagIndex === index,
                    },
                    "transition-all duration-200 ease-in-out"
                  )}
                >
                  {customTagRenderer ? (
                    customTagRenderer(tagObj, index === activeTagIndex)
                  ) : (
                    <Tag
                      tag={tagObj}
                      onRemoveTag={() => onRemoveTag(tagObj, index)}
                      isActiveTag={index === activeTagIndex}
                      direction={direction}
                      draggable={draggable}
                      tagClasses={classStyleProps?.tagClasses}
                      {...tagListProps}
                      disabled={disabled}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          tags.map((tagObj, index) =>
            customTagRenderer ? (
              customTagRenderer(tagObj, index === activeTagIndex)
            ) : (
              <Tag
                key={index}
                tag={tagObj}
                onRemoveTag={() => onRemoveTag(tagObj, index)}
                isActiveTag={index === activeTagIndex}
                direction={direction}
                draggable={draggable}
                tagClasses={classStyleProps?.tagClasses}
                {...tagListProps}
                disabled={disabled}
              />
            )
          )
        )}
      </div>
    );
  }

  return (
    <>
      {tags.length > 0 && (
        <CopyButton
          onClick={copyToClipboard}
          tooltipDefaultText="Copy as Comma Separated Values"
        />
      )}
      {draggable ? (
        <div className="flex flex-wrap gap-2 list">
          {tags.map((tagObj, index) => (
            <div key={index}>
              <div
                onMouseDown={() => handleMouseDown(index)}
                onMouseLeave={handleMouseUp}
                className={cn(
                  {
                    "border border-solid border-primary rounded-md":
                      draggedTagIndex === index,
                  },
                  "transition-all duration-200 ease-in-out"
                )}
              >
                {customTagRenderer ? (
                  customTagRenderer(tagObj, index === activeTagIndex)
                ) : (
                  <Tag
                    tag={tagObj}
                    onRemoveTag={() => onRemoveTag(tagObj, index)}
                    isActiveTag={index === activeTagIndex}
                    direction={direction}
                    draggable={draggable}
                    tagClasses={classStyleProps?.tagClasses}
                    {...tagListProps}
                    disabled={disabled}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        tags.map((tagObj, index) =>
          customTagRenderer ? (
            customTagRenderer(tagObj, index === activeTagIndex)
          ) : (
            <Tag
              key={index}
              tag={tagObj}
              onRemoveTag={() => onRemoveTag(tagObj, index)}
              isActiveTag={index === activeTagIndex}
              direction={direction}
              draggable={draggable}
              tagClasses={classStyleProps?.tagClasses}
              {...tagListProps}
              disabled={disabled}
            />
          )
        )
      )}
    </>
  );
};
