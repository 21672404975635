/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

// Copied and modified from https://github.com/JaleelB/emblor

import { cn } from "@/lib/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import type { Tag, TagInputStyleClassesProps } from "./tag-input";
import { TagList, type TagListProps } from "./tag-list";

type TagPopoverProps = {
  children: React.ReactNode;
  tags: Tag[];
  customTagRenderer?: (tag: Tag, isActiveTag: boolean) => React.ReactNode;
  activeTagIndex?: number | null;
  classStyleProps: {
    popoverClasses: TagInputStyleClassesProps["tagPopover"];
    tagListClasses: TagInputStyleClassesProps["tagList"];
    tagClasses: TagInputStyleClassesProps["tag"];
  };
  disabled?: boolean;
} & TagListProps;

export const TagPopover: React.FC<TagPopoverProps> = ({
  children,
  tags,
  customTagRenderer,
  activeTagIndex,
  classStyleProps,
  disabled,
  ...tagProps
}) => {
  const triggerContainerRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const popoverContentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [popoverWidth, setPopoverWidth] = useState<number>(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [sideOffset, setSideOffset] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (triggerContainerRef.current && triggerRef.current) {
        setPopoverWidth(triggerContainerRef.current.offsetWidth);
        setSideOffset(
          triggerContainerRef.current.offsetWidth -
            triggerRef?.current?.offsetWidth
        );
      }
    };

    handleResize(); // Call on mount and layout changes

    window.addEventListener("resize", handleResize); // Adjust on window resize
    return () => window.removeEventListener("resize", handleResize);
  }, [triggerContainerRef, triggerRef]);

  // Close the popover when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isPopoverOpen &&
        triggerContainerRef.current &&
        !triggerContainerRef.current.contains(event.target as Node | null) &&
        !popoverContentRef.current?.contains(event.target as Node | null)
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopoverOpen]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open && triggerContainerRef.current) {
        setPopoverWidth(triggerContainerRef.current.offsetWidth);
      }

      if (open) {
        inputRef.current?.focus();
        setIsPopoverOpen(open);
      }
    },
    [inputFocused]
  );

  const handleInputFocus = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    // Only set inputFocused to true if the popover is already open.
    // This will prevent the popover from opening due to an input focus if it was initially closed.
    if (isPopoverOpen) {
      setInputFocused(true);
    }

    (children as React.ReactElement).props?.onFocus?.(event);
  };

  const handleInputBlur = () => {
    setInputFocused(false);

    // Allow the popover to close if no other interactions keep it open
    if (!isPopoverOpen) {
      setIsPopoverOpen(false);
    }

    (children as React.ReactElement).props?.onBlur?.event;
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={handleOpenChange}>
      <div
        className="relative flex items-center rounded-md border border-input bg-transparent pr-3"
        ref={triggerContainerRef}
      >
        {React.cloneElement(children as React.ReactElement, {
          onFocus: handleInputFocus,
          onBlur: handleInputBlur,
          ref: inputRef,
        })}
        <PopoverTrigger asChild>
          <Button
            ref={triggerRef}
            variant="ghost"
            size="icon"
            role="combobox"
            className={cn(
              `hover:bg-transparent data-[state=open]:rotate-180`,
              classStyleProps?.popoverClasses?.popoverTrigger
            )}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-down h-4 w-4 shrink-0 opacity-50"
            >
              <path d="m6 9 6 6 6-6"></path>
            </svg>
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent
        ref={popoverContentRef}
        className={cn(
          `w-full space-y-3`,
          classStyleProps?.popoverClasses?.popoverContent
        )}
        style={{
          marginLeft: `-${sideOffset}px`,
          width: `${popoverWidth}px`,
        }}
      >
        <div className="space-y-1">
          <h4 className="text-sm font-medium leading-none">Entered Tags</h4>
          <p className="text-sm text-muted-foregrounsd text-left">
            These are the tags you&apos;ve entered.
          </p>
        </div>
        <TagList
          tags={tags}
          customTagRenderer={customTagRenderer}
          activeTagIndex={activeTagIndex}
          classStyleProps={{
            tagListClasses: classStyleProps?.tagListClasses,
            tagClasses: classStyleProps?.tagClasses,
          }}
          {...tagProps}
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
};
