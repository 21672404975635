/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-confusing-non-null-assertion */
"use client";

// Copied and modified from https://github.com/JaleelB/emblor

import { cn } from "@/lib/utils";
import { type VariantProps } from "class-variance-authority";
import React, { useMemo } from "react";
import { Button } from "../ui/button";
import { CommandInput } from "../ui/command";
import { Input } from "../ui/input";
import { type tagVariants } from "./tag";
import { TagAutocomplete } from "./tag-autocomplete";
import { TagList } from "./tag-list";
import { TagPopover } from "./tag-popover";

export enum Delimiter {
  Comma = ",",
  Enter = "Enter",
}

type OmittedInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "value"
>;

export type Tag = string;

export interface TagInputStyleClassesProps {
  inlineTagsContainer?: string;
  tagPopover?: {
    popoverTrigger?: string;
    popoverContent?: string;
  };
  tagList?: {
    container?: string;
    sortableList?: string;
  };
  autoComplete?: {
    command?: string;
    popoverTrigger?: string;
    popoverContent?: string;
    commandList?: string;
    commandGroup?: string;
    commandItem?: string;
  };
  tag?: {
    body?: string;
    closeButton?: string;
  };
  input?: string;
  clearAllButton?: string;
}

export interface TagInputProps
  extends OmittedInputProps,
    VariantProps<typeof tagVariants> {
  placeholder?: string;
  nonemptyPlaceholder?: string;
  values: Tag[];
  setValues: (values: Tag[]) => void;
  enableAutocomplete?: boolean;
  autocompleteOptions?: Tag[];
  maxTags?: number;
  minTags?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onTagAdd?: (tag: string) => void;
  onTagRemove?: (tag: string, index: number) => void;
  allowDuplicates?: boolean;
  validateTag?: (tag: string) => boolean;
  delimiter?: Delimiter;
  showCount?: boolean;
  placeholderWhenFull?: string;
  sortTags?: boolean;
  delimiterList?: string[];
  truncate?: number;
  minLength?: number;
  maxLength?: number;
  usePopoverForTags?: boolean;
  autocompleteFilter?: (option: string) => boolean;
  direction?: "row" | "column";
  onInputChange?: (value: string) => void;
  customTagRenderer?: (tag: Tag, isActiveTag: boolean) => React.ReactNode;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onTagClick?: (tag: Tag) => void;
  draggable?: boolean;
  inputFieldPosition?: "bottom" | "top";
  clearAll?: boolean;
  onClearAll?: () => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  restrictTagsToAutocompleteOptions?: boolean;
  inlineTags?: boolean;
  activeTagIndex?: number | null;
  setActiveTagIndex?: React.Dispatch<React.SetStateAction<number | null>>;
  styleClasses?: TagInputStyleClassesProps;
}

const TagInput = React.forwardRef<HTMLInputElement, TagInputProps>(
  (props, ref) => {
    const {
      id,
      placeholder,
      nonemptyPlaceholder = placeholder,
      values: tags,
      setValues: setTags,
      variant,
      className,
      enableAutocomplete,
      autocompleteOptions,
      maxTags,
      delimiter = Delimiter.Comma,
      onTagAdd,
      onTagRemove,
      allowDuplicates,
      showCount,
      validateTag,
      placeholderWhenFull = "Max tags reached",
      sortTags,
      delimiterList,
      truncate,
      autocompleteFilter,
      minLength,
      maxLength,
      direction = "row",
      onInputChange,
      customTagRenderer,
      onFocus,
      onBlur,
      onTagClick,
      draggable = false,
      inputFieldPosition = "bottom",
      clearAll = false,
      onClearAll,
      usePopoverForTags = false,
      inputProps = {},
      restrictTagsToAutocompleteOptions,
      inlineTags = true,
      activeTagIndex,
      setActiveTagIndex,
      styleClasses = {},
      disabled,
    } = props;

    const [inputValue, setInputValue] = React.useState("");
    const inputRef = React.useRef<HTMLInputElement>(null);

    const tagCount = useMemo(() => tags.length, [tags]);

    if (
      (maxTags !== undefined && maxTags < 0) ||
      (props.minTags !== undefined && props.minTags < 0)
    ) {
      console.warn("maxTags and minTags cannot be less than 0");
      // error
      return null;
    }

    const tryToAddTag = (tag: string) => {
      const newTagText = tag.trim();

      if (newTagText === "") return;

      // Check if the tag is in the autocomplete options if restrictTagsToAutocomplete is true
      if (
        restrictTagsToAutocompleteOptions &&
        !autocompleteOptions?.some((option) => option === newTagText)
      ) {
        // error
        return;
      }

      if (validateTag && !validateTag(newTagText)) {
        return;
      }

      if (minLength && newTagText.length < minLength) {
        console.warn("Tag is too short");
        // error
        return;
      }

      // Validate maxLength
      if (maxLength && newTagText.length > maxLength) {
        // error
        console.warn("Tag is too long");
        return;
      }

      if (
        newTagText &&
        (allowDuplicates || !tags.some((tag) => tag === newTagText)) &&
        (maxTags === undefined || tags.length < maxTags)
      ) {
        setTags([...tags, newTagText]);
        onTagAdd?.(newTagText);
      }
      setInputValue("");
    };

    const tryToAddManyTags = (tagsToAdd: string[]) => {
      tagsToAdd = tagsToAdd
        .map((tag) => tag.trim())
        .filter((tag) => tag)
        .filter((tag) => allowDuplicates || !tags.includes(tag));
      const newTags = [...tags];
      for (const tag of tagsToAdd) {
        if (maxTags !== undefined && newTags.length >= maxTags) break;
        newTags.push(tag);
      }
      setTags(newTags);
      if (onTagAdd) newTags.forEach(onTagAdd);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      onInputChange?.(newValue);
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setActiveTagIndex?.(null); // Reset active tag index when the input field gains focus
      onFocus?.(event);
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      tryToAddTag(inputValue);
      onBlur?.(event);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        delimiterList
          ? delimiterList.includes(e.key)
          : e.key === (delimiter as string) ||
            e.key === (Delimiter.Enter as string)
      ) {
        e.preventDefault();
        tryToAddTag(inputValue);
      } else {
        switch (e.key) {
          case "Delete":
            if (activeTagIndex != null) {
              e.preventDefault();
              const newTags = [...tags];
              newTags.splice(activeTagIndex, 1);
              setTags(newTags);
              setActiveTagIndex?.((prev) =>
                newTags.length === 0
                  ? null
                  : prev! >= newTags.length
                    ? newTags.length - 1
                    : prev
              );
            } else if (tags.length > 0 && !inputValue) {
              e.preventDefault();
              const newTags = [...tags];
              newTags.pop();
              setTags(newTags);
            }
            break;
          case "Backspace":
            if (activeTagIndex != null) {
              e.preventDefault();
              const newTags = [...tags];
              newTags.splice(activeTagIndex, 1);
              setTags(newTags);
              setActiveTagIndex?.((prev) => (prev! === 0 ? null : prev! - 1));
            } else if (tags.length > 0 && !inputValue) {
              e.preventDefault();
              const newTags = [...tags];
              newTags.pop();
              setTags(newTags);
            }
            break;
          case "ArrowRight":
            if (!setActiveTagIndex) return;
            e.preventDefault();
            if (activeTagIndex == null) {
              setActiveTagIndex(0);
            } else {
              setActiveTagIndex((prev) =>
                prev! + 1 >= tags.length ? 0 : prev! + 1
              );
            }
            break;
          case "ArrowLeft":
            if (!setActiveTagIndex) return;
            e.preventDefault();
            if (activeTagIndex == null) {
              setActiveTagIndex(tags.length - 1);
            } else {
              setActiveTagIndex((prev) =>
                prev! === 0 ? tags.length - 1 : prev! - 1
              );
            }
            break;
          case "Home":
            if (!setActiveTagIndex) return;
            e.preventDefault();
            setActiveTagIndex(0);
            break;
          case "End":
            if (!setActiveTagIndex) return;
            e.preventDefault();
            setActiveTagIndex(tags.length - 1);
            break;
        }
      }
    };

    const handleInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const text = inputValue + e.clipboardData.getData("Text");

      function splitWithDelimiters(text: string) {
        if (delimiterList) {
          return delimiterList.reduce(
            (acc, delimiter) => acc.flatMap((text) => text.split(delimiter)),
            [text]
          );
        } else {
          const delimiterToSplitterMap: Record<Delimiter, string> = {
            [Delimiter.Comma]: ",",
            [Delimiter.Enter]: "\n",
          };
          return text.split(delimiterToSplitterMap[delimiter]);
        }
      }

      const split = splitWithDelimiters(text)
        .map((text) => text.trim())
        .filter((text) => text);

      if (split.length <= 1) {
        // If there is only one tag, let the input handle it normally
        return;
      } else {
        e.preventDefault();
        tryToAddManyTags(split);
      }
    };

    const removeTag = (tag: string, index: number) => {
      setTags(tags.filter((_, i) => i !== index));
      onTagRemove?.(tag, index);
    };

    const handleClearAll = () => {
      if (!onClearAll) {
        setActiveTagIndex?.(-1);
        setTags([]);
        return;
      }
      onClearAll?.();
    };

    const filteredAutocompleteOptions = autocompleteFilter
      ? autocompleteOptions?.filter(autocompleteFilter)
      : autocompleteOptions;

    const displayedTags = sortTags ? [...tags].sort() : tags;

    const truncatedTags = truncate
      ? tags.map((tag) =>
          tag.length > truncate ? `${tag.substring(0, truncate)}...` : tag
        )
      : displayedTags;

    const actualPlaceholder =
      maxTags !== undefined && tags.length >= maxTags
        ? placeholderWhenFull
        : tags.length
          ? nonemptyPlaceholder
          : placeholder;

    return (
      <div
        className={cn(
          `w-full flex`,
          !inlineTags && tags.length > 0 && "gap-3",
          inputFieldPosition === "bottom"
            ? "flex-col"
            : inputFieldPosition === "top"
              ? "flex-col-reverse"
              : "flex-row",
          className
        )}
        ref={ref}
      >
        {!usePopoverForTags &&
          (!inlineTags ? (
            <TagList
              tags={truncatedTags}
              customTagRenderer={customTagRenderer}
              variant={variant}
              onTagClick={onTagClick}
              draggable={draggable}
              onRemoveTag={removeTag}
              direction={direction}
              inlineTags={inlineTags}
              activeTagIndex={activeTagIndex}
              classStyleProps={{
                tagListClasses: styleClasses?.tagList,
                tagClasses: styleClasses?.tag,
              }}
              disabled={disabled}
            />
          ) : (
            !enableAutocomplete && (
              <div className="w-full">
                <div
                  className={cn(
                    `flex flex-row flex-wrap items-center gap-sm p-sm w-full rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`,
                    styleClasses?.inlineTagsContainer
                  )}
                >
                  <TagList
                    tags={truncatedTags}
                    customTagRenderer={customTagRenderer}
                    variant={variant}
                    onTagClick={onTagClick}
                    draggable={draggable}
                    onRemoveTag={removeTag}
                    direction={direction}
                    inlineTags={inlineTags}
                    activeTagIndex={activeTagIndex}
                    classStyleProps={{
                      tagListClasses: styleClasses?.tagList,
                      tagClasses: styleClasses?.tag,
                    }}
                    disabled={disabled}
                  />
                  <Input
                    ref={inputRef}
                    id={id}
                    type="text"
                    placeholder={actualPlaceholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onPaste={handleInputPaste}
                    {...inputProps}
                    className={cn(
                      "h-5 py-0 border-0 bg-transparent focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 flex-1 w-fit shadow-none",
                      styleClasses?.input
                    )}
                    autoComplete={enableAutocomplete ? "on" : "off"}
                    list={
                      enableAutocomplete ? "autocomplete-options" : undefined
                    }
                    disabled={
                      disabled ||
                      (maxTags !== undefined && tags.length >= maxTags)
                    }
                  />
                </div>
              </div>
            )
          ))}
        {enableAutocomplete ? (
          <div className="w-full">
            <TagAutocomplete
              tags={tags}
              setTags={setTags}
              autocompleteOptions={filteredAutocompleteOptions!}
              maxTags={maxTags}
              onTagAdd={onTagAdd}
              allowDuplicates={allowDuplicates ?? false}
              inlineTags={inlineTags}
              classStyleProps={{
                command: styleClasses?.autoComplete?.command,
                popoverTrigger: styleClasses?.autoComplete?.popoverTrigger,
                popoverContent: styleClasses?.autoComplete?.popoverContent,
                commandList: styleClasses?.autoComplete?.commandList,
                commandGroup: styleClasses?.autoComplete?.commandGroup,
                commandItem: styleClasses?.autoComplete?.commandItem,
              }}
            >
              {!usePopoverForTags ? (
                !inlineTags ? (
                  <CommandInput
                    placeholder={actualPlaceholder}
                    ref={inputRef}
                    value={inputValue}
                    disabled={
                      disabled ||
                      (maxTags !== undefined && tags.length >= maxTags)
                    }
                    onChangeCapture={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className={cn(
                      "w-full",
                      // className,
                      styleClasses?.input
                    )}
                  />
                ) : (
                  <div
                    className={cn(
                      `flex flex-row flex-wrap items-center gap-sm p-sm h-fit w-full bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`,
                      styleClasses?.inlineTagsContainer
                    )}
                  >
                    <TagList
                      tags={truncatedTags}
                      customTagRenderer={customTagRenderer}
                      variant={variant}
                      onTagClick={onTagClick}
                      draggable={draggable}
                      onRemoveTag={removeTag}
                      direction={direction}
                      inlineTags={inlineTags}
                      activeTagIndex={activeTagIndex}
                      classStyleProps={{
                        tagListClasses: styleClasses?.tagList,
                        tagClasses: styleClasses?.tag,
                      }}
                      disabled={disabled}
                    />
                    <CommandInput
                      placeholder={actualPlaceholder}
                      ref={inputRef}
                      value={inputValue}
                      disabled={
                        disabled ||
                        (maxTags !== undefined && tags.length >= maxTags)
                      }
                      onChangeCapture={handleInputChange}
                      onKeyDown={handleKeyDown}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className={cn(
                        "border-0 flex-1 w-fit h-5",
                        // className,
                        styleClasses?.input
                      )}
                    />
                  </div>
                )
              ) : (
                <TagPopover
                  tags={truncatedTags}
                  customTagRenderer={customTagRenderer}
                  variant={variant}
                  onTagClick={onTagClick}
                  draggable={draggable}
                  onRemoveTag={removeTag}
                  direction={direction}
                  activeTagIndex={activeTagIndex}
                  classStyleProps={{
                    popoverClasses: styleClasses?.tagPopover,
                    tagListClasses: styleClasses?.tagList,
                    tagClasses: styleClasses?.tag,
                  }}
                  disabled={disabled}
                >
                  <CommandInput
                    placeholder={actualPlaceholder}
                    ref={inputRef}
                    value={inputValue}
                    disabled={
                      disabled ||
                      (maxTags !== undefined && tags.length >= maxTags)
                    }
                    onChangeCapture={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className={cn(
                      "w-full",
                      // className,
                      styleClasses?.input
                    )}
                  />
                </TagPopover>
              )}
            </TagAutocomplete>
          </div>
        ) : (
          <div className="w-full">
            {!usePopoverForTags ? (
              !inlineTags ? (
                <Input
                  ref={inputRef}
                  id={id}
                  type="text"
                  placeholder={actualPlaceholder}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onPaste={handleInputPaste}
                  {...inputProps}
                  className={cn(styleClasses?.input)}
                  autoComplete={enableAutocomplete ? "on" : "off"}
                  list={enableAutocomplete ? "autocomplete-options" : undefined}
                  disabled={
                    disabled ||
                    (maxTags !== undefined && tags.length >= maxTags)
                  }
                />
              ) : null
            ) : (
              <TagPopover
                tags={truncatedTags}
                customTagRenderer={customTagRenderer}
                variant={variant}
                onTagClick={onTagClick}
                draggable={draggable}
                onRemoveTag={removeTag}
                direction={direction}
                activeTagIndex={activeTagIndex}
                classStyleProps={{
                  popoverClasses: styleClasses?.tagPopover,
                  tagListClasses: styleClasses?.tagList,
                  tagClasses: styleClasses?.tag,
                }}
                disabled={disabled}
              >
                <Input
                  ref={inputRef}
                  id={id}
                  type="text"
                  placeholder={actualPlaceholder}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onPaste={handleInputPaste}
                  {...inputProps}
                  autoComplete={enableAutocomplete ? "on" : "off"}
                  list={enableAutocomplete ? "autocomplete-options" : undefined}
                  disabled={
                    disabled ||
                    (maxTags !== undefined && tags.length >= maxTags)
                  }
                  className={cn(
                    "border-0 w-full shadow-none",
                    styleClasses?.input
                    // className
                  )}
                />
              </TagPopover>
            )}
          </div>
        )}

        {showCount && maxTags && (
          <div className="flex">
            <span className="text-muted-foreground text-sm mt-1 ml-auto">
              {`${tagCount}`}/{`${maxTags}`}
            </span>
          </div>
        )}
        {clearAll && (
          <Button
            type="button"
            onClick={handleClearAll}
            className={cn("mt-2", styleClasses?.clearAllButton)}
          >
            Clear All
          </Button>
        )}
      </div>
    );
  }
);

TagInput.displayName = "TagInput";

export { TagInput };
