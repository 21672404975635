// Copied and modified from https://github.com/JaleelB/emblor

import React from "react";
import { Button } from "../ui/button";
import type {
  TagInputProps,
  TagInputStyleClassesProps,
  Tag as TagType,
} from "./tag-input";

import { cn } from "@/lib/utils";
import { Cross2Icon } from "@radix-ui/react-icons";
import { cva } from "class-variance-authority";

export const tagVariants = cva(
  "inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        brandhighlight:
          "border-transparent bg-surface-brandHighlight text-text shr:bg-surface-brandHighlight/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground bg-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export type TagProps = {
  tag: TagType;
  variant: TagInputProps["variant"];
  onRemoveTag: () => void;
  isActiveTag?: boolean;
  tagClasses?: TagInputStyleClassesProps["tag"];
  disabled?: boolean;
} & Pick<TagInputProps, "direction" | "onTagClick" | "draggable">;

export const Tag: React.FC<TagProps> = ({
  tag,
  direction,
  draggable,
  onTagClick,
  onRemoveTag,
  variant,
  isActiveTag,
  tagClasses,
  disabled,
}) => {
  return (
    <span
      draggable={draggable}
      className={cn(
        tagVariants({
          variant,
        }),
        {
          "justify-between w-full": direction === "column",
          "cursor-pointer": draggable,
          "ring-ring ring-offset-2 ring-2 ring-offset-background": isActiveTag,
        },
        tagClasses?.body
      )}
      onClick={() => onTagClick?.(tag)}
    >
      {tag}
      <Button
        type="button"
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up to the tag span
          onRemoveTag();
        }}
        disabled={disabled}
        className={cn(
          `ml-xs px-0 h-full rounded-full hover:bg-accent`,
          tagClasses?.closeButton
        )}
      >
        <Cross2Icon className="text-text-secondary hover:text-black transition-colors" />
      </Button>
    </span>
  );
};
